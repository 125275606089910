import { GetNowListening, GetMyPlaylists } from "./VisLib.js";
import "./Dashboard.css";
import React, { useState, useEffect, useRef } from "react";
import { getCookie } from "../utils/cookies.js";
import { useNavigate } from "react-router-dom";

function Dashboard() {
  const navigate = useNavigate();
  const [isSfyConnected, setIsSfyConnected] = useState(null);
  const [userName, setUserName] = useState(null);
  const hasCheckedSession = useRef(false); // Prevent duplicate calls

  useEffect(() => {
    if (!hasCheckedSession.current) {
      const cookieString = getCookie("sessionUser");

      if (!cookieString) {
        // If no cookie is found, redirect to the login page
        navigate("/login");
      } else {
        // If cookie exists, parse the user object
        const user = JSON.parse(cookieString);
        setIsSfyConnected(user.isSfyConnected); // Use the parsed user data
        setUserName(user.lfmUsername); // Set the username from the cookie
      }

      hasCheckedSession.current = true; // Prevent further execution
    }
  }, [navigate]);

  const handleSfyLink = async () => {
    const scopes = [
      "user-read-private",
      "user-read-email",
      "user-library-read",
      "user-read-recently-played",
      "playlist-read-private",
      "user-read-currently-playing",
      "playlist-read-collaborative",
      "user-top-read"
    ];
    const redirectUri = `https://accounts.spotify.com/authorize?client_id=${process
      .env
      .REACT_APP_SPOTIFY_CLIENT_ID}&response_type=code&redirect_uri=${process
        .env.REACT_APP_SPOTIFY_REDIRECT_URI}&scope=${scopes.join(" ")}`;
    window.location.href = redirectUri;
  };

  if (isSfyConnected === null) {
    return <div>Loading...</div>;
  }

  return (
    <div className="dashboard">
      <div className="main-content">
        {!isSfyConnected ? (
          <div className="link-spotify">
            <p>Your account has not been linked with Spotify yet.</p>
            <button onClick={handleSfyLink}>Connect</button>
          </div>
        ) : (
          <div>
            <div className="listening-container">
              <h3>Now Playing:</h3>
              <GetNowListening />
            </div>
            <div className="playlists-container">
              <h3>My playlists:</h3>
              <GetMyPlaylists />
            </div>
          </div>
        )}
      </div>
      <div className="announcement-container">
        <h2>Charts & Metrics Coming Soon!</h2>
        <p>Stay tuned for exciting data visualizations and insights about your listening habits.</p>
      </div>
    </div>
  );
}

export default Dashboard;
