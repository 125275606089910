import React, { useState, useEffect } from "react";
import authApi from "../api/authApi";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { getCookie } from "../utils/cookies"; // Assuming you're using this to check for cookies
import "./LoginForm.css"; // Import the CSS file

const LoginForm = ({ isLoggedIn, setIsLoggedIn }) => {
  const [isRegister, setIsRegister] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFn] = useState("");
  const [lastName, setLn] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(null);
  const [response, setResponse] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Verify if a session exists in cookies or session storage before redirection
    const checkSession = () => {
      const sessionCookie = getCookie("sessionUser"); // Check for your session cookie
      if (sessionCookie && !isLoggedIn) {
        setIsLoggedIn(true); // Set the state to logged in
        navigate("/dashboard"); // Redirect to dashboard
      }
    };

    if (!isLoggedIn) {
      checkSession();
    }
  }, [isLoggedIn, setIsLoggedIn, navigate]);

  const handleLogin = async () => {
    setLoading(true);
    try {
      const data = await authApi.login(email, password);
      setResponse(data.message);
      setIsLoggedIn(true);
      setError("");
      setLoading(false);
      navigate("/dashboard"); // Ensure navigation happens after login
    } catch (err) {
      setLoading(false);
      console.log(err);
      setError("Invalid email or password");
      setResponse("");
    }
  };

  const handleRegister = async () => {
    if (password !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Check if the email is valid
    if (!emailRegex.test(email)) {
      setError("Invalid email format.");
      return;
    }

    setLoading(true);
    try {
      const data = await authApi.register(email, password, firstName, lastName);
      setResponse(data.message);
      setError("");
      setLoading(false);
      navigate("/login");
    } catch (err) {
      setLoading(false);
      console.log(err);
      setError("Registration failed.");
      setResponse("");
    }
  };

  const toggleRegister = () => {
    setIsRegister(!isRegister);
  };

  const isRegisterButtonDisabled =
    password !== confirmPassword || password === "" || confirmPassword === "";

  return (
    <div>
      <div className="auth-container">
        {isRegister ? (
          <div>
            <h2>Register</h2>

            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
            />
            <div className="password-container">
              <input
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
              />
              <FontAwesomeIcon
                icon={showPassword ? faEyeSlash : faEye}
                className="toggle-password-icon"
                onClick={() => setShowPassword(!showPassword)}
              />
            </div>

            <div className="password-container">
              <input
                type={showPassword ? "text" : "password"}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="Confirm Password"
              />
              <FontAwesomeIcon
                icon={showPassword ? faEyeSlash : faEye}
                className="toggle-password-icon"
                onClick={() => setShowPassword(!showPassword)}
              />
            </div>
            <input
              type="first"
              value={firstName}
              onChange={(e) => setFn(e.target.value)}
              placeholder="First Name"
            />
            <input
              type="last"
              value={lastName}
              onChange={(e) => setLn(e.target.value)}
              placeholder="Last Name"
            />

            <button
              onClick={handleRegister}
              disabled={isRegisterButtonDisabled}
            >
              Register
            </button>
          </div>
        ) : (
          <div>
            <h2>Login</h2>

            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
            />
            <div className="password-container">
              <input
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
              />
              <FontAwesomeIcon
                icon={showPassword ? faEyeSlash : faEye}
                className="toggle-password-icon"
                onClick={() => setShowPassword(!showPassword)}
              />
            </div>
            <button onClick={handleLogin} disabled={loading}> {/* Disable button while loading */}
              {loading ? "Logging in..." : "Login"}
            </button>
          </div>
        )}

        <button onClick={toggleRegister}>
          {isRegister ? "Back to Login" : "Sign up here"}
        </button>
        {error && <p>{error}</p>}
        {response && <p className="success">Response: {response}</p>}
      </div>
      <p className="resume-link">
        Want to hire me?&nbsp;<a href="/resume">Heres my Resume</a>
      </p>
    </div>
  );
};

export default LoginForm;
