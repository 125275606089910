// /api/sfyApi.js
import apiClient from "./apiClient";

const sfyApi = {
  auth: async () => {
    const response = await apiClient.get("/spotify/auth");
    return response.data;
  },

  callback: async (code, email) => {
    const response = await apiClient.post("/spotify/callback", {
      code,
      email
    });
    return response.data;
  },

  myPlaylists: async email => {
    const response = await apiClient.get("/spotify/me/playlists", {
      params: email
    });
    return response.data;
  },

  getCurrentPlaying: async () => {
    const response = await apiClient.get("/spotify/me/currently-playing");
    return response.data;
  }
};

export default sfyApi;
