import React, { useEffect, useState, useRef } from "react";
import sfyApi from '../api/sfyApi';
import placeholderImage from '../images/playlist.png'; // Import the placeholder image


// SPOTIFY get playlists
export const GetMyPlaylists = () => {
  const [sfyData, updateSfyData] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const hasFetched = useRef(false); // UseRef to track if the request has already been made

  useEffect(() => {
    const getPlaylists = async () => {
      try {
        setLoading(true); // Ensure loading state is set
        const data = await sfyApi.myPlaylists(); // Fetch playlists from the backend
        updateSfyData(data);
        setError(null); // Reset error state on successful fetch
      } catch (err) {
        console.error("Error fetching playlists:", err);
        setError("Whoops! Something went wrong with Spotify API.");
      } finally {
        setLoading(false); // Set loading to false once request is complete
      }
    };

    if (!hasFetched.current) {
      getPlaylists();
      hasFetched.current = true; // Mark as fetched to prevent duplicate calls
    }
  }, []);

  const buildPlaylistContainer = () => {
    if (loading) {
      return <p>Loading playlists...</p>;
    }

    if (error) {
      return <p>{error}</p>;
    }

    if (!sfyData || sfyData.length === 0) {
      return <p>No playlists found</p>;
    }

    return (
      <div className="playlist-container">
        <ol>
          {sfyData.map((item, index) => (
            <li key={index} className="playlist-item">
              {/* Display the playlist image if available, else show a placeholder */}
              <img
                src={item.images && item.images.length > 0 ? item.images[0].url : placeholderImage}
                alt={item.name}
                className="playlist-image"
              />
              {/* Playlist name with link */}
              <a href={item.external_urls.spotify} target="_blank" rel="noopener noreferrer" className="playlist-link">
                {item.name}
              </a>
            </li>
          ))}
        </ol>
      </div>
    );
  };

  return buildPlaylistContainer();
};



//LFM get now listening
export const GetNowListening = () => {
  const [sfyData, updateSfyData] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state

  useEffect(() => {
    const getNowListening = async () => {
      try {
        setLoading(true); // Ensure loading state is set
        const data = await sfyApi.getCurrentPlaying(); // Fetch now playing track from the backend
        updateSfyData(data);
        setError(null); // Reset error state on successful fetch
      } catch (err) {
        console.error("Error fetching now playing:", err);
        setError("Whoops! Something went wrong with Spotify API.");
      } finally {
        setLoading(false); // Set loading to false once request is complete
      }
    };

    // Call immediately and set an interval to fetch every 10 seconds
    getNowListening();
    const intervalId = setInterval(getNowListening, 20000); // 10000ms = 10 seconds

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array to run only on mount/unmount

  const buildNowListening = () => {

    if (error) {
      return <p>{error}</p>;
    }

    if (!sfyData || sfyData.length === 0) {
      return <p>Not playing music right now.... Play some Jams!</p>;
    }

    const trackName = sfyData.name;
    const artistNames = sfyData.artists.map(artist => artist.name).join(', ');
    const imageUrl = sfyData.album.images[2].url;

    return (
      <div className='now-playing'>
        <img
          src={imageUrl}
          alt={trackName}
          className="listening-image"
        />
        <div className="listening-text">
          <p>{trackName}</p>
          <p>{artistNames}</p>
        </div>
      </div>
    );
  };

  return buildNowListening();
};


// LFM Get top artists
export const GetTopArtists = ({ username }) => {
  const [lfmData, updateLfmData] = useState({});

  useEffect(() => {
    fetch(`https://ws.audioscrobbler.com/2.0/?method=user.gettopartists&user=${username}&api_key=${process.env.REACT_APP_LASTFM_API_KEY}&format=json&limit=10`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('error');
      })
      .then(data => updateLfmData(data))
      .catch(() =>
        updateLfmData({ error: 'Whoops! Something went wrong with Last.fm' })
      );
  }, [username]);

  const buildLastFmData = () => {
    const { error } = lfmData;
    const artists = lfmData?.topartists?.artist;

    if (error) {
      return <p>{error}</p>;
    }

    if (!artists) {
      return <p>Loading</p>;
    }

    return (
      <div>
        <ol>
          {artists.map((artist, index) => {
            const imageUrl = artist?.image?.find(img => img.size === "medium")?.["#text"] || '';
            return (
              <li key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                {/* Display artist image */}
                {imageUrl && (
                  <img
                    src={imageUrl}
                    alt={artist.name}
                    style={{ width: '50px', height: '50px', marginRight: '10px', borderRadius: '50%' }}
                  />
                )}
                {/* Display artist name */}
                <a href={artist.url} target="_blank" rel="noopener noreferrer">
                  {artist.name}
                </a>
              </li>
            );
          })}
        </ol>
      </div>
    );
  };


  return buildLastFmData();
};