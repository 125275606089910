import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import sfyApi from "../api/sfyApi";
import { getCookie, setCookie } from "../utils/cookies";

const Callback = () => {
  const navigate = useNavigate();
  const calledOnce = useRef(false); // Ref to track whether the function has been called

  useEffect(
    () => {
      const handleSpotifyCallback = async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get("code");

        if (code && !calledOnce.current) {
          try {
            calledOnce.current = true; // Set the flag to true to prevent further calls

            // Send the code to the backend to exchange for tokens

            // Get the sessionUser cookie
            const sessionUserCookie = getCookie("sessionUser");
            let sessionUser = sessionUserCookie
              ? JSON.parse(sessionUserCookie)
              : null;

            await sfyApi.callback(code, sessionUser.email);

            if (sessionUser) {
              // Update the isSfyConnected field
              sessionUser.isSfyConnected = true;

              // Save the updated sessionUser cookie
              const updatedSessionUserCookie = JSON.stringify(sessionUser);
              setCookie("sessionUser", updatedSessionUserCookie, {
                days: 7, // Same options as before
                secure: process.env.NODE_ENV === "production",
                sameSite: "Strict"
              });
            }

            // Redirect to the desired page
            navigate("/dashboard", { replace: true }); // Replace the history entry
            window.location.reload();
          } catch (error) {
            console.error("Error exchanging Spotify code:", error);
            // Handle error, possibly redirect to login or show a message
            navigate("/login");
          }
        } else {
          // If no code is found in the URL, redirect back to login
          navigate("/login");
        }
      };

      handleSpotifyCallback();
    },
    [navigate]
  );

  return (
    <div>
      <h2>Processing Spotify Authorization...</h2>
    </div>
  );
};

export default Callback;
